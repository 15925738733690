<script setup lang="ts">
import type { PageHierarchyNode } from '@integration-layer/ampliance/schemas/page-hierarchy-node-schema.localized'
import { useGAHierarchyPage } from '@integration-layer/composables/useGAEvent/useGAHierarchyPage'
import { getAvailableHrefCountryCombinations } from '@design-system/configs/countries'
import { cleanDoubleSlashes } from 'ufo'

const route = useRoute()
const { getLocale } = useI18n()
const locale = getLocale()
const deliveryKey = computed(() => {
  const segments = route.path.split('/').slice(1)
  const [loc, country] = segments[0].split('-')
  segments[0] = `${loc.toLowerCase()}-${country.toUpperCase()}`
  return segments.join('/')
})
const vse = route.query.vse! as string

const url = `/api/cms/getByKey?locale=${locale}&id=${deliveryKey.value}&type=key&vse=${vse ?? ''}`

const nuxtApp = useNuxtApp()
if (nuxtApp.payload['middleware_error']) {
  nuxtApp.payload['middleware_error'] = false
  throw createError({
    statusCode: 404,
    fatal: true,
  })
}
const { data, error } = await useFetch<PageHierarchyNode>(url, {
  key: url,
  getCachedData: (k, nuxtApp) => nuxtApp.payload.data[k],
})
if (error.value || !data.value) {
  // TODO: handle sentry / other errors
  throw createError({
    statusCode: 404,
    fatal: true,
  })
}

const availableHrefCountryCombinations = getAvailableHrefCountryCombinations()
const hrefs = data.value?.hrefs?.filter(i =>
  availableHrefCountryCombinations.includes(i.locale)
)

const alternateHrefs = computed(
  () =>
    hrefs
      ?.filter(i => i.locale !== 'en-WX')
      ?.map(i => ({
        rel: 'alternate',
        hreflang: i.locale,
        href: cleanDoubleSlashes(`${useRequestURL().origin}/${i.value}/`),
      })) ?? []
)

const xDefault = computed(() => {
  const defaultHref = hrefs?.find(i => i.locale === 'en-WX')

  return {
    rel: 'alternate',
    hreflang: 'x-default',
    href: defaultHref?.value
      ? cleanDoubleSlashes(`${useRequestURL().origin}/${defaultHref?.value}/`)
      : '',
  }
})

const { fullPath } = useRoute()
useHead({
  link: [
    xDefault.value,
    ...alternateHrefs.value,
    {
      rel: 'canonical',
      href: fullPath,
    },
  ],
})

const components = computed(() => {
  return [
    {
      deliveryId: deliveryKey.value,
      name: data.value!.schema!,
      locale: locale,
      vse: vse,
      content: data.value!.content[0],
    },
  ]
})

useGAHierarchyPage(data.value)
</script>

<template>
  <AmplienceDynamicContent :components />
</template>
